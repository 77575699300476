app.controller('ReportsOrdersCtrl', ['$scope', '$http', function($scope, $http){

    $scope.orders = [];

    $http({
        method : 'POST',
        url : '/api/get/go',
        params : {
            url : API_URL + '/reports/orders',
            data : {

            }
        }
    }).then(function(response){
        if(response.data.result == true){
            $scope.orders = response.data.data;
        }else{
            $scope.orders = [];
        }

    });

}]);