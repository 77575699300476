app.controller('DashboardCtrl', ['$scope', '$http', function($scope, $http){


    $http({
        method : 'POST',
        url : '/api/get/go',
        params : {
            url : API_URL + '/reports/sales',
            data : {

            }
        }
    }).then(function(response){
        $scope.data = response.data;
    });


    $http({
        method : 'POST',
        url : '/api/get/go',
        params : {
            url : API_URL + '/reports/sales/projection',
            data : {

            }
        }
    }).then(function(response){
        $scope.dataSalesProjection = response.data;
    });


    $scope.options = {
        chart: {
            type: 'multiBarChart',
            height: 450,
            margin : {
                top: 20,
                right: 20,
                bottom: 45,
                left: 45
            },
            clipEdge: true,
            duration: 500,
            stacked: true,
            xAxis: {
                axisLabel: 'Date',
                showMaxMin: false

            },
            yAxis: {
                axisLabel: 'Total',
                axisLabelDistance: -20,
                tickFormat: function(d){
                    return d3.format(',.1f')(d);
                }
            }
        }
    };

    $scope.data = [{
        key: "Sales Last 7 Days",
        values: [

        ]
    }];

    $scope.dataSalesProjection = [{
        key: "Sales Next 7 Days",
        values: [

        ]
    }];






}]);